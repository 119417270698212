body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  text-wrap: nowrap;
}

.modal-table thead th{
  text-align: center;
}
.modal-table .column-person{
  text-align: left;
}
.modal-table tbody td{
  text-align: right;
}
.modal-table tbody th{
  text-align: right;
}
.modal-table tfoot th{
  text-align: right;
}
